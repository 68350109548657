import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const commonStyle = {
  display: "flex",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
  py: 2,
  px: 3,
  gap: "8px",
};
const ExperienceCard = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...commonStyle,
        background: theme.palette.primary.main,
        color: "#fff",
        borderRadius: "14px",
        border: "3px solid #fff",
        left: "-42%",
        bottom: "48%",
        "@media(max-width: 768px)": {
          px: 2,
          py: 1,
          left: "-38%",
        },
      }}
    >
      <CheckCircleIcon
        height='40px'
        sx={{
          "@media(max-width: 768px)": {
            height: "30px",
          },
        }}
      />

      <Typography
        variant='body1'
        lineHeight={"1.2"}
        component={"p"}
        fontSize='22px'
        width={"100px"}
        color={"#fff"}
        sx={{
          "@media(max-width: 768px)": {
            fontSize: "18px",
          },
        }}
      >
        Super Easy
      </Typography>
    </Box>
  );
};
const CustomerCard = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...commonStyle,
        background: "#fff",
        borderRadius: "14px",
        border: "3px solid #fff",
        right: "-32%",
        bottom: "24%",
        color: theme.palette.primary.main,
        "@media(max-width: 768px)": {
          px: 2,
          py: 1,
          bottom: "30%",
        },
      }}
    >
      <Typography
        variant='h3'
        fontWeight={700}
        component={"h3"}
        fontSize='28px'
        sx={{
          "@media(max-width: 768px)": {
            fontSize: "18px",
          },
        }}
      >
        100+
      </Typography>
      <Typography
        variant='body1'
        lineHeight={"22px"}
        component={"p"}
        fontWeight={500}
        fontSize='14px'
        width={"64px"}
      >
        Options
      </Typography>
    </Box>
  );
};

export { ExperienceCard, CustomerCard };
