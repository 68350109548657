import React from "react";
import {
  Dialog,
  Box,
  Typography,
  Grid,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextInput from "./TextInput";
import { Formik } from "formik";
import SubmitButton from "./SubmitButton";
import * as yup from "yup";
const LeadCaptureDialog = ({ open, onClose, selectedLob }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        fullScreen={isMobile}
        maxWidth='sm'
      >
        <DialogContent>
          <Box
            sx={{
              p: 3,
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 0,
                alignItems: "center",
                mb: 5,
              }}
            >
              <Typography
                variant={"h5"}
                component={"h3"}
                color='#2D2C5F'
                fontWeight={600}
                fontSize={"24px"}
              >
                Enter Your Details
              </Typography>
              <IconButton
                onClick={onClose}
                sx={{
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                pincode: "",
              }}
              onSubmit={(values) => {
                fetch("https://apibeta.vkover.com/api/SubmitLeadData", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    FNAME: values.first_name,
                    LNAME: values.last_name,
                    EMAIL: values.email,
                    MOBILE: values.phone,
                    PINCODE: values.pincode,
                    LOB: selectedLob,
                  }),
                });
                onClose();
              }}
              validationSchema={yup.object().shape({
                first_name: yup.string().required("First name is required"),
                last_name: yup.string().required("Last name is required"),
                email: yup
                  .string()
                  .email("Email is invalid")
                  .required("Email is required"),
                phone: yup
                  .string()
                  .required("Phone is required")
                  .matches(/^[5-9]{1}[0-9]{9}$/, "Phone is invalid"),
                pincode: yup
                  .string()
                  .required("Pincode is required")
                  .matches(/^[1-9]{1}[0-9]{5}$/, "Pincode is invalid"),
              })}
            >
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <TextInput label='First Name' name='first_name' />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextInput label='Last Name' name='last_name' />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextInput label='Email' name='email' />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextInput
                    label='Phone'
                    name='phone'
                    inputProps={{
                      maxLength: "10",
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextInput
                    label='Pincode'
                    name='pincode'
                    inputMode='numeric'
                    inputProps={{
                      maxLength: "10",
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <SubmitButton>Submit</SubmitButton>
                </Grid>
              </Grid>
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LeadCaptureDialog;
