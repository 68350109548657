import React from "react";
import "./Hero.css";
import beardedMan from "./../images/bearded-man.png";
import { Container, useMediaQuery } from "@mui/material";
import Header from "./Header";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import Products from "./Products";
import { CustomerCard, ExperienceCard } from "./InfoCard";

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <main className='hero'>
        <Box
          sx={{
            position: "absolute",
            height: "76%",
            bottom: "0",
            right: "200px",
            "@media (max-width: 768px)": {
              right: "50%",
              height: "330px",
              transform: "translateX(50%)",
              bottom: "140px",
            },
          }}
        >
          <img
            className='main-img'
            src={beardedMan}
            aria-label='Excited Man'
            alt='Excited Man'
          />

          <ExperienceCard />
          <CustomerCard />
        </Box>
        <Header isTransparent />
        <Container maxWidth='lg'>
          <Box
            width='541px'
            mt={"80px"}
            mb={2}
            sx={{
              "@media(max-width: 768px)": {
                width: "100%",
                mt: "0px",
              },
            }}
          >
            <Typography
              variant='h3'
              component={"h3"}
              fontSize='40px'
              color={theme.palette.text.secondary}
              sx={{
                mb: 1,
                "@media(max-width: 768px)": {
                  fontSize: "28px",
                },
              }}
            >
              <strong> Discover</strong> the perfect{" "}
              <strong
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                plan for you.
              </strong>
            </Typography>
            {/* <Typography
              variant='subtitle1'
              component={"p"}
              sx={{
                "@media(max-width: 768px)": {
                  fontSize: "12px",
                },
              }}
              fontSize='15px'
            >
              We cover all your insurance needs under one roof.
            </Typography> */}
          </Box>

          {!isMobile && <Products />}
        </Container>
      </main>
      {isMobile && <Products />}
    </>
  );
};

export default Hero;
