import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import bajaj from "./../../images/companies/bajaj.png";
import cigna from "./../../images/companies/cigna.png";
import digit from "./../../images/companies/digit.png";
import hdfc from "./../../images/companies/hdfc.png";
import icici from "./../../images/companies/icici.png";
import reliance from "./../../images/companies/reliance.png";
import kotak from "./../../images/companies/kotak.png";
import LIC from "./../../images/companies/LIC.png";
import max from "./../../images/companies/max.png";
import bupa from "./../../images/companies/max-bupa.png";
import tataAia from "./../../images/companies/tata-aia.png";
import nationalInsurance from "./../../images/companies/national-insurance.png";
import pnb from "./../../images/companies/pnb.png";
import star from "./../../images/companies/star.png";
import newIndia from "./../../images/companies/new-india.png";

const Companies = () => {
  return (
    <Container
      sx={{
        boxShadow: "0px 8px 20px 5px rgba(217, 217, 217, 0.25)",
        py: 5,

        mb: "60px",
        mt: "60px",
      }}
    >
      <Typography
        variant={"h5"}
        component={"h3"}
        flexGrow={1}
        color='#2D2C5F'
        fontWeight={600}
        fontSize={"30px"}
        lineHeight={"1.2"}
        textAlign={"center"}
        mb={5}
      >
        Our Partners
      </Typography>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={max} alt='max' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={hdfc} alt='HDFC' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={bupa} alt='bupa' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={tataAia} alt='tata' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={digit} alt='digit' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={LIC} alt='lic' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={bajaj} alt='bajaj' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={star} alt='star' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={kotak} alt='kotak' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={reliance} alt='reliance' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={nationalInsurance} alt='national-insurance' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={pnb} alt='pnb' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={newIndia} alt='new-india' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={icici} alt='icici' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img src={cigna} alt='cigna' />
          </div>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <div className='company-card'>
            <img
              style={{
                width: "auto",
                height: "60px",
              }}
              src={
                "https://api.vkover.com/storage/images/company_logos/hdfcergo.svg"
              }
              alt='hdfc'
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Companies;
