import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
const ProductCard = ({ productName, productLogo, color, link, onClick }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "86px",
          backgroundColor: color,
          borderRadius: "11px",
          cursor: "pointer",
          "@media (max-width: 768px)": {
            height: "60px",
          },
        }}
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }
          window.open(link, "_self");
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            top: "-5px",
            left: "-5px",
            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.11)",
            borderRadius: "11px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            mt={2}
            variant={"h5"}
            fontWeight='500'
            color={theme.palette.text.secondary}
            fontSize={{
              xs: "13px",
              sm: "14px",
              md: "16px",
            }}
          >
            {productName}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            borderRadius: "50%",
            top: 0,
            backgroundColor: color,
            padding: "25px",
            left: "calc(50% - 5px)",
            transform: "translate(-50%,-50%)",
            "@media (max-width: 768px)": {
              padding: "15px",
              "& img": {
                width: "20px",
                height: "20px",
              },
            },
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            src={productLogo}
            alt={productName}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductCard;
