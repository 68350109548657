import React, { useState } from "react";
import { Paper, Container, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import ProductCard from "./ProductCard";
import health from "./../images/health.svg";
import Term from "./../images/Term.svg";
import Car from "./../images/Car.svg";
import Bike from "./../images/Bike.svg";
import LeadCaptureDialog from "./LeadCaptureDialog";

const Products = () => {
  const [open, setOpen] = useState(false);
  const [selectedLob, setSelectedLob] = useState("");
  const onClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  return (
    <>
      <Container
        maxWidth='lg'
        sx={{
          position: "absolute",
          bottom: "0",
          left: "50%",
          transform: "translate(-50%,50%)",
          "@media (max-width: 768px)": {
            position: "relative",
            transform: "unset",
            left: "unset",
            bottom: "unset",
            marginTop: "-220px",
          },
        }}
      >
        <Paper
          sx={{
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              px: 3,

              py: 3,
              "@media (max-width: 768px)": {
                px: "12px",
                py: "12px",
              },
            }}
            spacing={{
              md: 3,
              xs: 2,
              sm: 2,
            }}
            rowSpacing={5}
          >
            <Grid
              item
              md={2}
              sm={12}
              xs={12}
              sx={{
                "@media(max-width: 768px)": {
                  paddingTop: "0px !important",
                },
              }}
            >
              <Typography
                variant='h4'
                component={"h4"}
                color={theme.palette.text.secondary}
                fontSize='23px'
                fontWeight={500}
                sx={{
                  "@media (max-width: 768px)": {
                    textAlign: "center",
                  },
                }}
              >
                Choose the best{" "}
                <Typography
                  variant='inherit'
                  color={theme.palette.primary.main}
                >
                  Insurance
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={2} sm={6} xs={6}>
              <ProductCard
                color={"#4CC692"}
                productName={"Health"}
                productLogo={health}
                link={"https://account.vkover.com/"}
                onClick={() => {
                  setOpen(true);
                  setSelectedLob("HEALTH");
                }}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={6}>
              <ProductCard
                color={"#9949FF"}
                productName={"Car"}
                productLogo={Car}
                link={"https://account.vkover.com"}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={6}>
              <ProductCard
                color={"#3166EE"}
                productName={"Bike"}
                productLogo={Bike}
                link={"https://account.vkover.com"}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={6}>
              <ProductCard
                color={"#FE753A"}
                productName={"Life & Pension"}
                productLogo={Term}
                link={"https://account.vkover.com"}
                onClick={() => {
                  setOpen(true);
                  setSelectedLob("LIFE");
                }}
              />
            </Grid>
            <Grid item md={2} textAlign={"center"}>
              {/* <Link
                color={theme.palette.primary.main}
                sx={{
                  fontSize: "20px !important",
                }}
              >
                More{" "}
              </Link> */}
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <LeadCaptureDialog
        open={open}
        onClose={onClose}
        selectedLob={selectedLob}
      />
    </>
  );
};

export default Products;
