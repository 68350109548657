import {
  Grid,
  Typography,
  Container,
  Button,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import agent from "./../../images/agent.svg";
import "./POSPSection.css";
const POSPSection = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <Container maxWidth='lg'>
        <div className='posp-section'>
          <Grid
            container
            sx={{
              "@media (max-width: 768px)": {
                flexDirection: "column-reverse",
                gap: "30px",
              },
            }}
          >
            <Grid
              item
              md={6}
              sx={{
                textAlign: "right",
                "@media (max-width: 768px)": {
                  width: "100%",
                  textAlign: "left",
                },
              }}
            >
              <img src={agent} className='agent-image' alt='agent' />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                pl: "40px",
                "@media (max-width: 768px)": {
                  width: "100%",
                  pl: "0",
                },
              }}
            >
              <Typography
                variant={"h5"}
                component={"h3"}
                flexGrow={1}
                color='#2D2C5F'
                fontWeight={600}
                fontSize={"30px"}
                maxWidth={"400px"}
                lineHeight={"1.2"}
              >
                Become an IRDAI Certified Insurance Advisor
              </Typography>
              <ul className='posp-list'>
                <li>Free POSP Certification under IRDAI guidelines</li>
                <li>Zero Investment business opportunity</li>
                <li>Attractive Pay-Outs</li>
                <li>Dedicated Relationship Manager support</li>
              </ul>
              <Button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/search?q=vkover&c=apps",
                    "_self"
                  )
                }
              >
                Register Now
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};
export default POSPSection;
